/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-10 17:03:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-10 17:31:35
 */
export const columns = [
{
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '所属大区',
    dataIndex: 'area',
    key: 'area',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
